<template>
  <div id="template_table">
    <div class="table">
      <div class="table_header">
        <div class="table_line_container">
          <div class="table_line_container__item type_column" style="border: unset">Шаблон</div>
          <div class="table_line_container__item action_column">Действия</div>
        </div>
      </div>
      <div v-if="templates.length > 0" class="table_content">
        <div
          class="table_line_container"
          v-for="(template, i) in templates"
          :key="i"
          :class="!(i % 2) ? 'blue' : 'white'"
        >
          <div
            class="table_line_container__item type_column"
            :title="template.template"
          >
            <label>{{template.template}}</label>
          </div>
          <div class="table_line_container__item action_column">
            <div class="action_buttons_container">
              <div
                class="action_buttons_container__item primary_actions_container"
              >
                <div class="primary_actions_container__item">
                  <button
                    class="primary_button"
                    title="Изменить"
                    @click="$emit('selectTemplate', template.template)"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.66659 9.99935H13.3333V11.666H6.66659V9.99935ZM8.33325 16.666H4.99992V3.33268H10.8333V7.49935H14.9999V10.0827L16.6666 8.41602V6.66602L11.6666 1.66602H4.99992C4.55789 1.66602 4.13397 1.84161 3.82141 2.15417C3.50885 2.46673 3.33325 2.89065 3.33325 3.33268V16.666C3.33325 17.108 3.50885 17.532 3.82141 17.8445C4.13397 18.1571 4.55789 18.3327 4.99992 18.3327H8.33325V16.666ZM6.66659 14.9993H10.0833L10.8333 14.2493V13.3327H6.66659V14.9993ZM16.8333 10.8327C16.9166 10.8327 17.0833 10.916 17.1666 10.9993L18.2499 12.0827C18.4166 12.2493 18.4166 12.5827 18.2499 12.7493L17.4166 13.5827L15.6666 11.8327L16.4999 10.9993C16.5833 10.916 16.6666 10.8327 16.8333 10.8327ZM16.8333 14.0827L11.7499 19.166H9.99992V17.416L15.0833 12.3327L16.8333 14.0827Z"
                        fill="#010101"
                      />
                    </svg>
                  </button>
                </div>
                <div class="primary_actions_container__item">
                  <button
                    class="primary_button"
                    title="Создать копию шаблона"
                    @click="$emit('copyTemplate', template.template)"
                  >
                  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.16667 17.3327C1.70833 17.3327 1.31583 17.1696 0.989167 16.8435C0.663055 16.5168 0.5 16.1243 0.5 15.666V3.99935H2.16667V15.666H11.3333V17.3327H2.16667ZM5.5 13.9993C5.04167 13.9993 4.64944 13.8363 4.32333 13.5102C3.99667 13.1835 3.83333 12.791 3.83333 12.3327V2.33268C3.83333 1.87435 3.99667 1.48185 4.32333 1.15518C4.64944 0.829071 5.04167 0.666016 5.5 0.666016H13C13.4583 0.666016 13.8508 0.829071 14.1775 1.15518C14.5036 1.48185 14.6667 1.87435 14.6667 2.33268V12.3327C14.6667 12.791 14.5036 13.1835 14.1775 13.5102C13.8508 13.8363 13.4583 13.9993 13 13.9993H5.5ZM5.5 12.3327H13V2.33268H5.5V12.3327Z" fill="#010101"></path></svg>
                  </button>
                </div>
                <div class="primary_actions_container__item">
                  <button
                  class="primary_button"
                    title="Удалить"
                    @click="$emit('deleteTemplate', template.template)"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.83325 17.5C5.37492 17.5 4.9827 17.3369 4.65659 17.0108C4.32992 16.6842 4.16659 16.2917 4.16659 15.8333V5H3.33325V3.33333H7.49992V2.5H12.4999V3.33333H16.6666V5H15.8333V15.8333C15.8333 16.2917 15.6702 16.6842 15.3441 17.0108C15.0174 17.3369 14.6249 17.5 14.1666 17.5H5.83325ZM14.1666 5H5.83325V15.8333H14.1666V5ZM7.49992 14.1667H9.16658V6.66667H7.49992V14.1667ZM10.8333 14.1667H12.4999V6.66667H10.8333V14.1667Z"
                        fill="#010101"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="not_found_content" v-else>Не найдено</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateTable",
  props: {
    templates: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },

  methods: {
    // switch sort type
    wrapNullValue(value) {
      return !value ? "Неизвестно" : value;
    },
  },

  computed: {
  },
  created() {
  },
};
</script>

<style lang="less">
#template_table {
  &::-webkit-scrollbar {
    display: none;
  }

  .table {
    margin-top: 15px;
    margin-bottom: 200px;
    .table_header {
      font-weight: 500;
      font-size: 15px;
      font-family: Roboto;
      font-style: normal;
      line-height: 18px;
      margin-bottom: 10px;

      .table_line_container {
        height: 30px;
        .table_line_container__item {
          border-left: 1px solid rgba(65, 80, 183, 0.3);
          padding: 10px 11px 11px;
          height: 100%;
          font-size: 15px;
        }
      }
    }
    .table_line_container {
      display: flex;
      height: 40px;
      align-items: center;

      &.white {
        background: none;
      }
      &.blue {
        background: #f1f6ff;
      }
      .table_line_container__item {
        display: flex;
        justify-content: flex-start;
        white-space: nowrap;
        padding: 10px 11px 11px;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;

        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        label {
          max-width: 98%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.type_column {
          width: 70%;
        }

        &.action_column {
          width: 30%;
        }
      }
    }
    .table_content {
      overflow-y: scroll;
      max-height: calc(100vh - 370px);
    }
    .not_found_content {
      display: flex;
      justify-content: center;
      height: 200px;
      align-items: center;
    }
    
  }
  .action_buttons_container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    .primary_button {
      all: unset;
      padding: 5px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      cursor: pointer;
      &.disable {
        &:hover {
          background-color: unset;
          cursor: unset;
        }
      }
      &:hover {
        background-color: #4151b720;
        border-radius: 10px;
        transition: 0.5s;
      }
    }
  }
  .primary_actions_container {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
}
</style>
