<template>
  <div id="template_editor">
    <div @click="$emit('closeEdit')" class="template_editor_content">
      <div @click.stop class="editor_container">
        <div class="editor_container__item template_header_container">
          <div class="template_header_container_item title">
            <label v-if="mode === 'EDIT'">{{ template.template }}</label>
            <label v-if="mode === 'CREATE'">
              {{
                onSaveJson !== null ? onSaveJson.template : template.template
              }}
            </label>
          </div>
          <div
            class="template_header_container_item template_buttons_container"
          >
            <div class="template_buttons_container__item">
              <button
                @click="onSave()"
                :class="allowSave ? 'blue_button' : 'gray_button not_available'"
              >
                Сохранить
              </button>
            </div>
            <div class="template_buttons_container__item">
              <button @click="$emit('closeEdit')" class="blue_button">
                Отмена
              </button>
            </div>
          </div>
        </div>
        <div class="editor_container__item editor">
          <Vue3JsonEditor
            v-model="template"
            @json-change="onJsonChange"
            @has-error="isValid = false"
            :modes="['code', 'tree']"
            :show-btns="true"
            mode="code"
            :expandedOnStart="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue3JsonEditor } from "vue3-json-editor";
export default {
  components: {
    Vue3JsonEditor,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    mode: {
      type: String,
      // EDIT/CREATE
      default() {
        return "EDIT";
      },
    },
  },
  data() {
    return {
      template: {},
      onSaveJson: null,
      isValid: true,
      templateName: null,
    };
  },

  watch: {
    "data.template"() {
      this.template = {};
    },
    templateName(newValue) {
      this.template.template = newValue;
    },
  },

  mounted() {
    this.template = this.data ? this.data : {};
    if (this.mode === "CREATE") {
      this.templateName = this.data.template;
    }
  },

  computed: {
    allowSave() {
      return this.isValid && (this.onSaveJson || this.mode === "CREATE");
    },
  },

  methods: {
    onSave() {
      if (this.allowSave) {
        if (this.onSaveJson === null) {
          this.onSaveJson = this.template;
        }
        this.$emit("saveTemplate", this.onSaveJson);
        // this.$emit("closeEdit");
      }
    },
    onJsonChange(value) {
      this.onSaveJson = value;
      this.isValid = true;
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
#template_editor {
  .template_editor_content {
    position: absolute;
    background-color: #17171788;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .editor_container {
      // margin-top: -3%;
      height: 95%;
      width: 90%;
      background-color: #ffffff;
      border-radius: 15px;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);

      .template_header_container {
        height: 40px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 20px;
        .title {
          flex-grow: 1;

          input {
            border: 1px #4150b7;
          }
        }
        .template_buttons_container {
          display: flex;
          .template_buttons_container__item {
            margin-right: 5px;
          }
        }
      }
      .editor {
        // overflow-y: scroll;
        .jsoneditor-undo:disabled {
          background-position: -24px -96px;
        }
        .jsoneditor-redo:disabled {
          background-position: -48px -96px;
        }
        .jsoneditor-poweredBy {
          display: none;
        }
        .jsoneditor-search {
          .jsoneditor-frame {
            border-radius: 5px;
          }
          .jsoneditor-refresh {
            margin-left: 10px;
          }
          input {
            padding: 0px;
          }
        }
        .jsoneditor {
          border: unset;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
        }
        .jsoneditor-menu {
          background-color: #4150b7;

          .jsoneditor-redo:disabled {
            color: red;
          }
        }
        .jsoneditor-type-modes {
          &.jsoneditor-selected {
            border-radius: 10px;
            background-color: #4150b7;
            color: #ffffff;
          }
        }

        .jsoneditor-contextmenu ul {
          padding: 0px 10px 10px 10px;
          border-radius: 10px;
          background-color: #ffffff;
          color: #4150b7;
          li {
            color: #4150b7;
            margin-top: 5px;
            button {
              &:hover {
                border-radius: 10px;
                background-color: #37373721;
                color: #000000;
              }
            }
          }

          // color: #ffffff;
          // &:hover {
          //   color: #4150b7;
          // }
        }
        .jsoneditor-vue {
          height: 100vh;
          border: unset;
        }
        .jsoneditor-mode-code {
          // height: 95vh;
          height: 100vh;
          max-height: 90%;
        }
        .jsoneditor-mode-text {
          height: 100vh;
          max-height: 90%;
        }
        .jsoneditor-mode-form {
          height: 100vh;
          max-height: 90%;
        }
        .jsoneditor-mode-tree {
          height: 100vh;
          max-height: 90%;
        }
      }
    }
  }
}
</style>
