<template>
  <div id="template_content">
    <notify-bar ref="notifyBar" />
    <dialog-window
      @submit-template-delete="onDeleteTemplateDialogWindow"
      @cancel="onCancelTemplateDialogWindow"
      :show="dialogWindow.show"
      :msg="dialogWindow.msg"
      :submitEvent="dialogWindow.submitEvent"
    />
    <template-editor
      v-if="selectedTemplate !== null"
      @save-template="onSaveTemplate"
      @close-edit="selectedTemplate = null"
      :data="getSelectedTemplate"
      :mode="mode"
    />
    <div class="template_content_container">
      <div class="template_content_container__item title_container">
        <div class="title_container__item title">
          <label>Шаблоны</label>
        </div>
        <div class="title_container__item">
          <button @click="onCreateTemplate" class="blue_button">Создать</button>
        </div>
      </div>
      <div class="template_content_container__item default_template_container">
        <div class="default_template_container__item title">
          <label>Шаблон по умолчанию</label>
        </div>
        <div class="default_template_container__item template_container">
          <div class="template_container__item title">
            
            <label v-if="defaultTemplate">{{ defaultTemplate.template }}</label>
            <div v-else class="spring-spinner icon_waiting">
              <div class="spring-spinner-part top">
                <div class="spring-spinner-rotator"></div>
              </div>
              <div class="spring-spinner-part bottom">
                <div class="spring-spinner-rotator"></div>
              </div>
            </div>
          </div>
          <div class="template_container__item action_buttons_container">
            <div class="action_buttons_container__item">
              <button @click="onSelectTemplate(defaultTemplate.template)">
                <svg
                  width="16"
                  height="19"
                  viewBox="0 0 16 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.66659 9.0013H10.3333V10.668H3.66659V9.0013ZM5.33325 15.668H1.99992V2.33464H7.83325V6.5013H11.9999V9.08464L13.6666 7.41797V5.66797L8.66659 0.667969H1.99992C1.55789 0.667969 1.13397 0.843563 0.821407 1.15612C0.508847 1.46868 0.333252 1.89261 0.333252 2.33464V15.668C0.333252 16.11 0.508847 16.5339 0.821407 16.8465C1.13397 17.159 1.55789 17.3346 1.99992 17.3346H5.33325V15.668ZM3.66659 14.0013H7.08325L7.83325 13.2513V12.3346H3.66659V14.0013ZM13.8333 9.83464C13.9166 9.83464 14.0833 9.91797 14.1666 10.0013L15.2499 11.0846C15.4166 11.2513 15.4166 11.5846 15.2499 11.7513L14.4166 12.5846L12.6666 10.8346L13.4999 10.0013C13.5833 9.91797 13.6666 9.83464 13.8333 9.83464ZM13.8333 13.0846L8.74992 18.168H6.99992V16.418L12.0833 11.3346L13.8333 13.0846Z"
                    fill="#010101"
                  />
                </svg>
              </button>
            </div>
            <div class="action_buttons_container__item">
              <button
                class="primary_button"
                title="Создать копию шаблона"
                @click="onCopyTemplate(defaultTemplate.template)"
              >
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.16667 17.3327C1.70833 17.3327 1.31583 17.1696 0.989167 16.8435C0.663055 16.5168 0.5 16.1243 0.5 15.666V3.99935H2.16667V15.666H11.3333V17.3327H2.16667ZM5.5 13.9993C5.04167 13.9993 4.64944 13.8363 4.32333 13.5102C3.99667 13.1835 3.83333 12.791 3.83333 12.3327V2.33268C3.83333 1.87435 3.99667 1.48185 4.32333 1.15518C4.64944 0.829071 5.04167 0.666016 5.5 0.666016H13C13.4583 0.666016 13.8508 0.829071 14.1775 1.15518C14.5036 1.48185 14.6667 1.87435 14.6667 2.33268V12.3327C14.6667 12.791 14.5036 13.1835 14.1775 13.5102C13.8508 13.8363 13.4583 13.9993 13 13.9993H5.5ZM5.5 12.3327H13V2.33268H5.5V12.3327Z"
                    fill="#010101"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="template_content_container__item custom_template_container">
        <div class="custom_template_container__item title">Кастомные шаблоны</div>
        <div class="custom_template_container__item search_input">
          <div class="search_input__item icon">
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.50006 0C8.53765 0 11.0001 2.46256 11.0001 5.50029C11.0001 6.74868 10.5842 7.89993 9.88346 8.82304L13.7791 12.7233C14.0718 13.0164 14.0715 13.4913 13.7785 13.784C13.4854 14.0767 13.0105 14.0764 12.7178 13.7834L8.82266 9.88388C7.89959 10.5847 6.74839 11.0006 5.50006 11.0006C2.46246 11.0006 0 8.53802 0 5.50029C0 2.46256 2.46246 0 5.50006 0ZM5.50006 1.5C3.2909 1.5 1.5 3.29098 1.5 5.50029C1.5 7.70961 3.2909 9.50058 5.50006 9.50058C7.70921 9.50058 9.50011 7.70961 9.50011 5.50029C9.50011 3.29098 7.70921 1.5 5.50006 1.5Z"
                fill="#A1A3AB"
              ></path>
            </svg>
          </div>
          <div class="search_input_item search_text" :class="filterName ? 'typed' : ''">
            <input
              @input="(event) => (filterName = event.target.value)"
              placeholder="Template"
            />
          </div>
        </div>
        <div class="custom_template_container__item templates_table">
          <template-table
            @copy-template="onCopyTemplate"
            @select-template="onSelectTemplate"
            @delete-template="onDeleteTemplate"
            :templates="getFilteredTemplates"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateTable from "../components/TemplateTable.vue";
import TemplateEditor from "../components/TemplateEditor.vue";
import DialogWindow from "../components/DialogWindow.vue";
import NotifyBar from "../components/NotifyBar.vue";

export default {
  components: {
    "dialog-window": DialogWindow,
    "notify-bar": NotifyBar,
    "template-table": TemplateTable,
    "template-editor": TemplateEditor,
  },
  data() {
    return {
      templates: [],
      defaultTemplate: null,
      selectedTemplate: null,
      mode: "EDIT",
      copyReference: null,

      toDelTemplate: null,
      filterName: null,
      dialogWindow: {
        show: false,
        msg: "",
        submitEvent: "submitTemplateDelete",
      },
    };
  },
  methods: {
    getTemplates() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/templates`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy obtained (get templates)");
          if (res.data.statusCode === 200) {
            this.templates = res.data.payload.templates.sort((a, b) => {
              let x = a.template.toLowerCase();
              let y = b.template.toLowerCase();
              if (x > y) {
                return 1;
              }
              if (x < y) {
                return -1;
              }
              return 0;
            });
            this.defaultTemplate = res.data.payload.templates.find((el) => el.isDefault);
            return;
          } else {
            console.log("Response proxy error (get templates)", res.data);
          }
        })
        .catch((error) => {
          console.log("Error to get templates", error);
        });
    },
    onSaveTemplate(template) {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Cохранение шаблона",
      });
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/templates`,
          method: "POST",
          payload: template,
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy api obtained (save template)", res);

          if ([200, 201].includes(res.data.statusCode)) {
            this.getTemplates();
            this.selectedTemplate = null;
            this.$refs.notifyBar.addOKMsg({
              overWriteMsg: "Шaблон сохранен",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка сохранения шаблона",
          });
        })
        .catch((error) => {
          console.log("Error to save template", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка сохранения шаблона",
          });
        });
    },
    onCreateTemplate() {
      this.mode = "CREATE";
      this.selectedTemplate = -1;
      this.copyReference = null;
    },
    onCopyTemplate(template) {
      this.mode = "CREATE";
      this.selectedTemplate = -1;
      this.copyReference = template;
    },

    onSelectTemplate(template) {
      this.selectedTemplate = template;
    },
    onDeleteTemplate(type) {
      this.toDelTemplate = type;
      this.dialogWindow = {
        show: true,
        msg: `Удалить шаблон: ${type}`,
        submitEvent: "submitTemplateDelete",
      };
    },
    onDeleteTemplateDialogWindow() {
      this.deleteTemplate(
        this.templates.find((el) => el.template === this.toDelTemplate).template
      );
      this.dialogWindow.show = false;
    },
    onCancelTemplateDialogWindow() {
      this.dialogWindow.show = false;
    },
    deleteTemplate(template) {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Удаление шаблона",
      });
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/templates?template=${template}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy api obtained (del template)", res);

          if (res.data.statusCode === 200) {
            this.getTemplates();
            this.$refs.notifyBar.addOKMsg({
              overWriteMsg: "Шаблон удален",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка удаления шаблона",
          });
        })
        .catch((error) => {
          console.log("Error to del template", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка удаления шаблона",
          });
        });
    },
    createCopyRefName(root) {
      let count = 1;
      let name = null;
      for (;;) {
        name = `${root}_copy(${count})`;
        if (this.templates.find((el) => el.template === name) === undefined) {
          return name;
        }
        count += 1;
      }
    },
  },
  created() {
    this.getTemplates();
  },
  computed: {
    getFilteredTemplates() {
      if (this.filterName) {
        return this.templates.filter((el) => {
          return el.template.includes(this.filterName);
        });
      }
      return this.templates;
    },

    getSelectedTemplate() {
      if (this.selectedTemplate === -1) {
        if (this.copyReference !== null) {
          const template = {
            ...this.templates.find((el) => el.template === this.copyReference),
          };
          template.template = this.createCopyRefName(this.copyReference);
          template.isDefault = false;
          return template;
        }
        return {
          data: {
            images: [],
            onReceiveActions: [],
          },
          isDefault: false,
          template: "",
        };
      }
      return this.templates.find((el) => el.template === this.selectedTemplate);
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
@import "../assets/styles/inputs.less";
@import "../assets/styles/animations.less";



#template_content {
  .defineSpinner(20px);
  .template_content_container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .title_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      font-family: Roboto;
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .tool_bar_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .blue_button {
      height: 20px;
    }
  }

  .template_container {
    background-color: #f1f6ff;
    width: 499px;
    height: 41px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border-radius: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    margin-bottom: 20px;

    .title {
      width: 404px;
    }

    .action_buttons_container {
      display: flex;
      padding: 10px;
      width: 95px;
      justify-content: space-evenly;
      button {
        all: unset;
        padding: 5px 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &.disable {
          &:hover {
            background-color: unset;
            cursor: unset;
          }
        }
        &:hover {
          background-color: #4151b720;
          border-radius: 10px;
          transition: 0.5s;
        }
      }
    }
  }

  .default_template_container {
    display: flex;
    flex-direction: column;

    .default_template_container__item {
      &.title {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        margin-bottom: 15px;
      }
    }
  }

  .custom_template_container {
    display: flex;
    flex-direction: column;
    font-family: Roboto;

    .search_input {
      width: 487px;
    }
    .custom_template_container__item {
      &.title {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
